.CardBody-module__CardBody {
  padding: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.CardBody-module__finance {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-top: auto;
  
}

.CardBody-module__monthlyPrice,
.CardBody-module__sellingPrice,
.CardBody-module__oldPrice {
  display: flex;
  gap: 4px;
}

.CardBody-module__monthlyPrice > strong {
  font-size: 18px;
  line-height: 24px;
  color: #154f9c;
  margin-top: auto;
  display: block;
}

.CardBody-module__monthlyPrice > span {
  font-size: 12px;
  line-height: 24px;
  color: #154f9c;
  margin-top: auto;
  display: block;
}

.CardBody-module__totalPrice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: auto;
}

body[dir="ltr"] .CardBody-module__totalPrice {
  margin-right: unset;
  margin-left: auto;
}

.CardBody-module__sellingPrice > strong {
  font-size: 18px;
  line-height: 24px;
  color: #00b362;
}

.CardBody-module__sellingPrice > span {
  font-size: 12px;
  line-height: 24px;
  color: #00b362;
}

.CardBody-module__oldPrice {
  position: relative;
  /* min-height: 24px; */
}

.CardBody-module__oldPrice::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 51%;
  background: #697a8f;
  transform: rotate(-2deg);
}

.CardBody-module__oldPrice > strong {
  font-size: 16px;
  line-height: 16px;
  color: #697a8f;
}

.CardBody-module__oldPrice > span {
  font-size: 12px;
  line-height: 16px;
  color: #697a8f;
}

.CardBody-module__vatIncluded {
  font-size: 12px;
  line-height: 16px;
  color: #697a8f;
}

.CardBody-module__guaranteedLabel {
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: #eff6ff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  min-height: 20px;
}

.CardBody-module__guaranteedLabel > strong {
  /* font-size: 8px; */
  font-size: 10px;
  line-height: 16px;
  color: #154f9c;
}

.CardBody-module__title {
  color: #484848;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
}
@media screen and (min-width: 1025px) {
  .CardBody-module__finance {
    min-height: 56px;
  }
}